var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-chat-queryMSG" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-plus",
          attrs: { slot: "right-one" },
          on: {
            click: function($event) {
              return _vm.handlerCreateGroup()
            }
          },
          slot: "right-one"
        })
      ]),
      _c("van-search", {
        attrs: { placeholder: "", "input-align": "center" },
        on: {
          focus: function($event) {
            return _vm.$router.push({ name: "search-msg" })
          }
        }
      }),
      _vm.list.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: {
                    distance: 150,
                    onTop: _vm._onTop,
                    onBottom: _vm._onBottom
                  },
                  expression:
                    "{\n    distance: 150,\n    onTop: _onTop,\n    onBottom: _onBottom\n  }"
                }
              ],
              staticClass: "me-chat-queryMSG__list"
            },
            [
              _c(
                "van-cell-group",
                { staticClass: "me-chat-queryMSG__list--group" },
                _vm._l(_vm.list, function(item, index) {
                  return _c("van-cell", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "line-title",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [
                        !_vm.notice.includes(item.group_type)
                          ? _c("Avatar", {
                              attrs: {
                                src: item.avatar,
                                width: "45px",
                                height: "45px",
                                id: item.id,
                                isGroup: item.isGroup
                              }
                            })
                          : _c(
                              "div",
                              {
                                class: ["notice-" + item.group_type, "notice"]
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.formartNotice(item.group_type)
                                  }
                                })
                              ]
                            ),
                        _c("van-icon", {
                          class: {
                            "msg-dot": true,
                            hidden: !item.msgNum || item.msgNum == 0
                          },
                          attrs: { info: item.msgNum }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "line-title__info",
                            on: {
                              click: function($event) {
                                _vm.notice.includes(item.group_type)
                                  ? _vm.$router.push({
                                      name: "notices",
                                      params: {
                                        id: item.id,
                                        type: item.group_type
                                      }
                                    })
                                  : _vm.handlerToChatCatch(item)
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "info" }, [
                              _c("span", { staticClass: "info-name" }, [
                                _vm._v(_vm._s(!item.isNotice ? item.name : ""))
                              ]),
                              _c("span", { staticClass: "info-time" }, [
                                _vm._v(_vm._s(_vm.parseTime(item.time)))
                              ])
                            ]),
                            !item.isNotice && item.messageData.data
                              ? _c("span", {
                                  staticClass: "last_msg",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      item.messageData.data.replace(
                                        /<\/?.+?\/?>/g,
                                        ""
                                      )
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        : _c("emptyData"),
      _c("Tabbar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }