<template>
  <div class="me-chat-queryMSG">
    <Header>
      <i class="me-icon-plus" slot="right-one" @click="handlerCreateGroup()"></i>
    </Header>
    <van-search      
    placeholder=""
    input-align="center"
    @focus="$router.push({name:'search-msg'})"
  />
    <div
      class="me-chat-queryMSG__list"
      v-scroll="{
      distance: 150,
      onTop: _onTop,
      onBottom: _onBottom
    }"
    v-if="list.length"
    >
      <van-cell-group class="me-chat-queryMSG__list--group">
        <van-cell v-for="(item, index) in list" :key="index">
          <div slot="title" class="line-title">
            <Avatar
              :src="item.avatar"
              width="45px"
              height="45px"
              :id="item.id"
              :isGroup="item.isGroup"
              v-if="!notice.includes(item.group_type)"
            />
            <div v-else :class="[`notice-${item.group_type}`,'notice']">
              <img  :src="formartNotice(item.group_type)" />
            </div>
            <van-icon :class="{'msg-dot':true,'hidden':!item.msgNum || item.msgNum == 0}" :info="item.msgNum" />
            <div
              class="line-title__info"
              @click="notice.includes(item.group_type) ? $router.push({name:'notices',params:{id:item.id,type:item.group_type}}) : handlerToChatCatch(item)"
            >
              <div class="info">
                <span class="info-name" >{{!item.isNotice ? item.name : ''}}</span>
                <span class="info-time">{{parseTime(item.time)}}</span>
              </div>
              <span class="last_msg" v-if="!item.isNotice && item.messageData.data" v-html="item.messageData.data.replace(/<\/?.+?\/?>/g,'')"></span>
            </div>
          </div>
        </van-cell>
      </van-cell-group>
    </div>
    <emptyData v-else />
    <Tabbar />
  </div>
</template>
<script>
import Tabbar from "@/components/common/tabbar";
import Header from "@/components/business/h5/header";
import Avatar from "@/components/common/avatar";
import { getQueryChatMessageEXT } from "@/api/imchat";
import emptyData from '@/components/common/emptyData'
import { env, getBrowserInfo, parseTime } from "@/utils";
import { Base64 } from "js-base64";
export default {
  name: "chat",
  components: {
    Tabbar,
    Avatar,
    Header,
    emptyData
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 30,
      list: [],
      notice:['7','8','9','10']
    };
  },
  created() {
    this.language = localStorage.getItem("langCode") || "1";
    this.handlerGetQueryChatMsgExt();
  },
  mounted(){
    this.$listener(async socketData => {
      if (socketData.code == 2) { 
        this.$send({ code: 2 });
        console.log("replay heartbeat", { code: 2 });
        return;
      }
      const data = socketData
     
      const target =
        data.type !== "receipt"
          ? data.from.slice(0, data.from.indexOf("@"))
          : data.header.from.slice(0, data.header.from.indexOf("@"));

       if (!data || !data.type || data.type === 'result') return;
      // const { chat } = this.$refs;
      const protocal = JSON.parse(data.protocal);
      const header = data.header;
      const {avatar,name,messageId,groupName}= protocal
      console.log(target,data.type,'/target');
      
      const item = {
        avatar: data.type === 'notice' ? this.formartNotice('8') :avatar,
        name: name || groupName,
        messageId,
        id:target.replace('G',''),
        isGroup:data.type === 'group' || data.type === 'groupchat' ? true : false,
        isNotice:data.type === "notice",
        group_type:data.type === "notice"? '8' : '',
        msgNum:1,
        time:header.servertimestamp,
        messageData:{
          data:protocal.data
        }

      }      
      this.list.some((el,index)=>{        
        if(el.id === item.id && el.messageId!== item.messageId){
          if(el.msgNum<100)
           el.msgNum++
          else el.msgNum = '99+'
          el.messageId = item.messageId
          el.messageData.data = item.messageData.data
          el.time = item.time
          this.list.unshift(el)
          this.list.splice(index+1,1)
        }
      })
       
      if(!this.list.some(el=>{
         return el.id === item.id && el.messageId === item.messageId
       }) && item.id){         
        this.list.unshift(item)
      }
      
      // this.list.unshift(item)
      //         avatar: item.avatar,
      //         name: item.groupName || item.userName,
      //         groupType: item.group_type,
      //         id: item.groupId || item.userId,
      //         isGroup: item.groupId ? true : false,
      //         msgNum: item.msgNum,
      //         isFriend: item.is_friend,
      //         messageData: item.messageData,
      //         time: item.lastTime,
      //         group_type:item.group_type,
      //         isNotice:this.notice.includes(item.group_type)      
    })
  },
  
  methods: {
    parseTime,
    handlerToChatCatch(item){
      let chatObj = {
        id:item.id,
        name:item.name,
        notice:this.notice.includes(item.group_type) || false,
        type:item.isGroup ? 'group' : 'chat'
      }
      localStorage.setItem('chatObj',JSON.stringify(chatObj))
      this.$router.push({
        name:'chatWith',
        params:{
          id:"pai_" + Base64.encode(item.id),
          type:chatObj.type
        }
      })
    },
    formartNotice(type){
      let icon = null
      switch(type){
        case '7':
          icon = require('../../assets/images/notice/44.png')
          break;
        case '8':
          icon = require('../../assets/images/notice/33.png')
          break
        case '9':
          icon = require('../../assets/images/notice/22.png')
          break
        case '10':
          icon = require('../../assets/images/notice/11.png')
          break
      }
      return icon
    },
    handlerCreateGroup(){
      localStorage.setItem('oldGroupMembers',JSON.stringify({id:'',members:[]}))
      this.$router.push({name:'friends',query:{selectModel:true}})
    },
    _onTop() {},
    _onBottom() {
      this.pageNum++;
      this.handlerGetQueryChatMsgExt();
    },
    async handlerGetQueryChatMsgExt() {
      const params = {
        msgType: "no_system",
        pageNum: this.pageNum,
        language: this.language,
        pageSize: this.pageSize
      };
      const result = await getQueryChatMessageEXT(params).catch(e => {});
      if (result && result.data) {
        this.list = this.list.concat(
          result.data.list.map(item => {            
            return {
              avatar: item.avatar,
              name: item.groupName || item.userName,
              groupType: item.group_type,
              id: item.groupId || item.userId,
              isGroup: item.groupId ? true : false,
              msgNum: item.msgNum,
              isFriend: item.is_friend,
              messageData: item.messageData,
              time: item.lastTime,
              group_type:item.group_type,
              isNotice:this.notice.includes(item.group_type)
            };
          })
        );
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
  .notice{
    width:45px;
    height:45px;
    border-radius:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:.5rem;
    &.notice-8{
      background: #ffba00
    }
    &.notice-7,&.notice-9,&.notice-10{
      background:#52bd68
    }
    img{
      width:35px;
      height:35px;
    }
    
  }
  

+b(me-chat-queryMSG) {
  margin: 54px 0;
  display: flex;
  flex-flow: column;
  min-height:84.5vh;

  +e(list) {
    flex: 1;
    overflow-y: scroll;
    max-height: 90vh;

    +m(group) {
      .van-cell {
        display: flex;
        flex-flow: column;

        +e(title) {
          display: flex;
          flex-flow: column;
          position: relative;
          overflow: hidden;

          .line-title {
            display: flex;
            flex-flow: row;
            align-items: flex-start;
            .msg-dot{
              position absolute
              left 40px;
              top 8px
            }

            +e(info) {
              display: flex;
              flex-flow: column;
              line-height: 22px;
              flex: 1;
              overflow: hidden;

              .info {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: flex-start;
                overflow: hidden;

                .info-name {
                  flex: 1;
                  font-size: 14px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .info-time {
                  display: inline;
                  text-align: right;
                  white-space: nowrap;
                  color: #999999;
                }
              }

              .last_msg {
                font-size: 12px;
                color: #999999;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }

        +e(label) {
          padding-left: 50px;
        }
      }
    }
  }
}
</style>